// @flow
import * as React from 'react';
import {Button} from "react-bootstrap";
import {BsFullscreen, BsFullscreenExit} from "react-icons/bs";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import FormBillItemsRow, {numberFormat} from "./FormBillItemsRow";
import * as Constants from '../../../constants'
import {BillFormContext} from "./BillForm";
import useApi from "../../../hooks/useApi";
import {useDispatch, useSelector} from "react-redux";
import {getBillItems, getSparePartsForLoadedBill} from "../../../services/API/damageBill.service";
import {getDeductionCode} from "../../../services/API/damageBills.service";
import {calculateItemTotalDeduction, calculateQuantityPriceZero} from "../../../utils/damageItemsCalculations";
import {checkLoggedUser} from "../../../features/Authentication/authSlice";
import {useOnClickOutSide} from "../../../hooks/useOnClickOutSide";
import toast from "react-hot-toast";
import {getAllCurrencies, getRoundNumberOfSwiss} from "../../../services/API/countries.service";
import {updateCurrency} from "../../../features/DamageBill/bill.actions";
import {Form} from "react-bootstrap";
import {usePopper} from "react-popper";
import {useCountries} from "../../../hooks/useCountries";


export function precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

//context menu
const initialContextMenu = {
    show: false,
    x: 0,
    y: 0
}

export function FormBillItems({showConvertedValue, hideConvertedValue}) {
    const {
        bill,
        isReadOnly,
        isTaxIncluded,
        billTax: firstVat,
        paymentTotal,
        currency
    } = useSelector(state => state.damageBill)
    const {warrantyContract, damage, country_id} = useSelector(state => state.damageBill);
    const {showSidebar} = useSelector(state => state.searchPagination);

    const {setBillFormClassNameForZoomedItems} = useContext(BillFormContext);
    const dispatch = useDispatch();
    const [selectedCurrency, setSelectedCurrency] = useState(currency?.foreignCurrency || null);

    useEffect(() => {
        setSelectedCurrency(currency?.foreignCurrency || null);
    }, [currency?.foreignCurrency]);

    const lala = useCallback((id) => {
        return getBillItems(id)
    }, [])

    const {
        data: sparePartsForLoadedBillData,
        request: requestSparePartsForLoadedBill
    } = useApi(getSparePartsForLoadedBill);
    useEffect(() => {

        warrantyContract?.contract_id && damage?.id && requestSparePartsForLoadedBill({
            contractId: warrantyContract?.contract_id,
            damageId: damage?.id
        });

    }, [requestSparePartsForLoadedBill, warrantyContract?.contract_id, damage?.id, bill])

    const {
        data: items,
        error: itemsError,
        request: requestItems,
        loading: loadingItems
    } = useApi(lala)

    const {
        data: getDeductionCodeData,
        request: getDeductionCodeRequest,
        error: getDeductionCodeError
    } = useApi(getDeductionCode);
    const [deductionCodeList, setDeductionCodeList] = useState([]);


    const {
        data: getCurrenciesData,
        request: getCurrenciesRequest,
        error: currencyError
    } = useApi(getAllCurrencies);

    const [rateHasError, setRateHasError] = useState(false);
    const [rate, setRate] = useState('');

    const onChangeCurrencyHandler = (e) => {
        setRate('');
        setRateHasError(false);
        setSelectedCurrency(e.target.value);
        dispatch(updateCurrency({
            billId: bill.id,
            domestic_currency: currency?.domesticCurrency,
            foreign_currency: e.target.value
        }));
    }

    const onClickRateForm = () => {
        if (rate === '' || parseFloat(rate) === 0) {
            setRateHasError(true);
            return;
        }
        dispatch(updateCurrency({
            billId: bill.id,
            domestic_currency: currency.domesticCurrency,
            foreign_currency: currency.foreignCurrency,
            rate: rate
        }));
    };

    const handleRateChange = (e) => {
        setRate(e.target.value);
        if (e.target.value === '' || parseFloat(e.target.value) === 0) {
            setRateHasError(true);
        } else setRateHasError(false);
    }

    const [bItems] = useState([]);

    const [focusedField, setFocusedField] = useState<[number, number]>([0, 0]);

    /**
     * When focusedField changes
     */
    useEffect(() => {
        document.querySelector(`[data-table-item="${focusedField[0]}${focusedField[1]}"]`)?.focus()
    }, [focusedField])

    // secondVat is hardcoded for now, will be read from configuration based on whom is sending bill
    const [secondVat] = useState(10.00);

    let [billItems, setBillItems] = useState([]);
    let [typeLaborTotal, setTypeLaborTotal] = useState(0);
    let [typeLaborTotalDeduction, setTypeLaborTotalDeduction] = useState(0);
    let [typeLaborTotalAfterDeduction, setTypeLaborTotalAfterDeduction] = useState(0);
    let [typeSparePartsTotal, setTypeSparePartsTotal] = useState(0);
    let [typeSparePartsTotalDeduction, setTypeSparePartsTotalDeduction] = useState(0);
    let [typeSparePartsTotalAfterDeduction, setTypeSparePartsTotalAfterDeduction] = useState(0);
    let [typeOtherTotal, setTypeOtherTotal] = useState(0);
    let [typeOtherTotalDeduction, setTypeOtherTotalDeduction] = useState(0);
    let [typeOtherTotalAfterDeduction, setTypeOtherTotalAfterDeduction] = useState(0);
    let [grandTotal, setGrandTotal] = useState(0);
    let [grandTotalDeduction, setGrandPriceTotalDeduction] = useState(0);
    let [grandTotalAfterDeduction, setGrandPriceTotalAfterDeduction] = useState(0);
    let [firstTax, setFirstTax] = useState(0);
    let [secondTax, setSecondTax] = useState(0);
    let [totalAfterTaxes, SetTotalAfterTaxes] = useState(0);
    let [zoomedIn, setZoomedIn] = useState(false);
    let [zoomInOutBtnClass, setZoomInOutBtnClass] = useState('notZoomed');
    let [zoomInOutBtnTitle, setZoomInOutBtnTitle] = useState('Click to edit data in full screen mode');
    const [contextMenu, setContextMenu] = useState(initialContextMenu);
    const contextMenuRef = useRef(null);

    const handleContextMenu = (e) => {
        e.preventDefault();
        const pageX = e.clientX;
        const pageY = e.clientY;
        setContextMenu({show: true, x: pageX, y: pageY});
    }
    const contextMenuClose = () => {
        setContextMenu(initialContextMenu);
    }
    useOnClickOutSide(contextMenuRef, contextMenuClose);

    useEffect(() => {
        if (!getDeductionCodeData) return;
        if (getDeductionCodeError) {
            toast.error(getDeductionCodeError);
        } else {
            if (!getDeductionCodeData._embedded) return;
            setDeductionCodeList([...getDeductionCodeData._embedded.records]);
        }
    }, [getDeductionCodeData, getDeductionCodeError]);

    useEffect(() => {
        getDeductionCodeRequest();
    }, [getDeductionCodeRequest]);

    useEffect(() => {
        getCurrenciesRequest();
    }, [getCurrenciesRequest]);

    useEffect(() => {
        if (currencyError) toast.error(currencyError);
    }, [currencyError]);

    const toggleZoomInZoomOutOfItemsAndTotalsHolder = useCallback((newZoomStatus) => {
        if (newZoomStatus === true) {
            setZoomedIn(true);
            setZoomInOutBtnClass('zoomed');
            setZoomInOutBtnTitle('Click to exit full screen mode');
            setBillFormClassNameForZoomedItems('zoomedItems');
        } else if (newZoomStatus === false) {
            setZoomedIn(false);
            setZoomInOutBtnClass('notZoomed');
            setZoomInOutBtnTitle('Click to edit data in full screen mode');
            setBillFormClassNameForZoomedItems('notZoomedItems');
        } else {
            if (zoomedIn) {
                setZoomedIn(false);
                setZoomInOutBtnClass('notZoomed');
                setZoomInOutBtnTitle('Click to edit data in full screen mode');
                setBillFormClassNameForZoomedItems('notZoomedItems');
            } else {
                setZoomedIn(true);
                setZoomInOutBtnClass('zoomed');
                setZoomInOutBtnTitle('Click to exit full screen mode');
                setBillFormClassNameForZoomedItems('zoomedItems');
            }
        }
    }, [setBillFormClassNameForZoomedItems, zoomedIn])

    useEffect(() => {
        if (bItems.length === 0) return;
        let tmpBillItems = [...bItems];
        setBillItems(tmpBillItems);
    }, [bItems])

    useEffect(() => {
        if (itemsError) return;
        if (!items || items?._embedded?.records?.length === 0) return;
        let tmpBillItems = items._embedded.records;
        setBillItems(tmpBillItems);
    }, [items, itemsError])


    useEffect(() => {
        if (!bill?.damage_bill_id) return
        setBillItems([]);
        requestItems({id: bill?.damage_bill_id});
    }, [bill, requestItems]);

    const calculateGrandTotals = useCallback((billItems) => {
        let tmpLabor = 0;
        let tmpLaborDeduction = 0;
        let tmpSpareParts = 0;
        let tmpSparePartsDeduction = 0;
        let tmpOther = 0;
        let tmpOtherDeduction = 0;
        let tmpTotal = 0;
        let tmpTotalDeduction = 0;
        let tmpTotalRow = 0;
        let tmpTotalDeductionRow = 0;
        let tmpFirstTax = 0;
        let tmpSecondTax = 0;
        let tmpTotalAfterTaxes = 0;
        let firstVatTmp = (firstVat?.length > 0 && !isNaN(parseFloat(firstVat))) ? parseFloat(firstVat).toFixed(2) : 0;

        let grandPriceTotalAfterDeduction = 0;
        if (billItems !== null && billItems.length) {
            billItems.forEach((item) => {
                tmpTotalRow = isNaN(item.total_for_paying) || item.total_for_paying === '' || item.total_for_paying === null ? calculateQuantityPriceZero(item) : parseFloat(item.total_for_paying);
                tmpTotalDeductionRow = calculateItemTotalDeduction(item);
                if (item.item_type === Constants.ITEM_TYPE_LABOR) {
                    tmpLabor += tmpTotalRow;
                    tmpLaborDeduction += tmpTotalDeductionRow;
                } else if (item.item_type === Constants.ITEM_TYPE_SPARE_PART) {
                    tmpSpareParts += tmpTotalRow;
                    tmpSparePartsDeduction += tmpTotalDeductionRow;
                } else {
                    tmpOther += tmpTotalRow;
                    tmpOtherDeduction += tmpTotalDeductionRow;
                }

                grandPriceTotalAfterDeduction += precisionRound(tmpTotalRow - tmpTotalDeductionRow, 2);

                /**
                 * Floating point precision fix
                 */
                tmpTotal += precisionRound(tmpTotalRow, 2)
                tmpTotalDeduction += precisionRound(tmpTotalDeductionRow, 2)

                tmpTotal = precisionRound(tmpTotal, 2)
                tmpTotalDeduction = precisionRound(tmpTotalDeduction, 2)

                if (item.item_type === Constants.ITEM_TYPE_SPARE_PART && item.is_taxable_for_second_tax) {
                    tmpSecondTax += ((tmpTotalRow - tmpTotalDeductionRow) * secondVat / 100) * (!isTaxIncluded ? 1 : (firstVat / 100));
                }
            });

            // 16.68 + 26.56 + 112.88 + 26.56 + 106.24 + 510.93 + 510.93 + 44.48 + 212.48 + 13.28

            setSecondTax(tmpSecondTax);

            tmpLabor = isNaN(tmpLabor) ? 0 : tmpLabor;
            tmpLaborDeduction = isNaN(tmpLaborDeduction) ? 0 : tmpLaborDeduction;

            tmpSpareParts = isNaN(tmpSpareParts) ? 0 : tmpSpareParts;
            tmpSparePartsDeduction = isNaN(tmpSparePartsDeduction) ? 0 : tmpSparePartsDeduction;

            tmpOther = isNaN(tmpOther) ? 0 : tmpOther;
            tmpOtherDeduction = isNaN(tmpOtherDeduction) ? 0 : tmpOtherDeduction;

            tmpTotal = isNaN(tmpTotal) ? 0 : tmpTotal;
            tmpTotalDeduction = isNaN(tmpTotalDeduction) ? 0 : tmpTotalDeduction;

            setTypeLaborTotal(!isNaN(tmpLabor) ? tmpLabor : precisionRound(0, 2));
            setTypeLaborTotalDeduction(!isNaN(tmpLaborDeduction) ? tmpLaborDeduction : precisionRound(0, 2));
            setTypeLaborTotalAfterDeduction(tmpLabor - tmpLaborDeduction)
            setTypeSparePartsTotal(!isNaN(tmpSpareParts) ? tmpSpareParts : precisionRound(0, 2))
            setTypeSparePartsTotalDeduction(!isNaN(tmpSparePartsDeduction) ? tmpSparePartsDeduction : precisionRound(0, 2))
            setTypeSparePartsTotalAfterDeduction(tmpSpareParts - tmpSparePartsDeduction)
            setTypeOtherTotal(!isNaN(tmpOther) ? tmpOther : precisionRound(0, 2));
            setTypeOtherTotalDeduction(!isNaN(tmpOtherDeduction) ? tmpOtherDeduction : precisionRound(0, 2));
            setTypeOtherTotalAfterDeduction(tmpOther - tmpOtherDeduction);
            setGrandTotal(tmpLabor + tmpSpareParts + tmpOther);
            setGrandPriceTotalDeduction(!isNaN(tmpTotalDeduction) ? tmpTotalDeduction : precisionRound(0, 2));
            setGrandPriceTotalAfterDeduction(!isNaN(grandPriceTotalAfterDeduction) ? grandPriceTotalAfterDeduction : precisionRound(0, 2));
            if (isTaxIncluded) {
                tmpFirstTax = (grandPriceTotalAfterDeduction) * (firstVatTmp) / 100;
            } else {
                tmpFirstTax = 0;
            }
            setFirstTax(tmpFirstTax);
            tmpTotalAfterTaxes = grandPriceTotalAfterDeduction + tmpFirstTax + tmpSecondTax;
            SetTotalAfterTaxes(!isNaN(tmpTotalAfterTaxes) ? precisionRound(tmpTotalAfterTaxes, 2) : precisionRound(0, 2));
        } else {
            setTypeLaborTotal(0);
            setTypeLaborTotalDeduction(0);
            setTypeLaborTotalAfterDeduction(0)
            setTypeSparePartsTotal(0)
            setTypeSparePartsTotalDeduction(0)
            setTypeSparePartsTotalAfterDeduction(0)
            setTypeOtherTotal(0);
            setTypeOtherTotalDeduction(0);
            setTypeOtherTotalAfterDeduction(0)
            setGrandTotal(0);
            setGrandPriceTotalDeduction(0);
            setGrandPriceTotalAfterDeduction(0)
            setFirstTax(0);
            setSecondTax(0);
            SetTotalAfterTaxes(0)
        }

    }, [firstVat, isTaxIncluded, secondVat])

    useEffect(() => {
        calculateGrandTotals(billItems);
    }, [billItems, isTaxIncluded, calculateGrandTotals])

    const updateItem = useCallback((index, changedItem, operation) => {
        if (operation === 'delete') {
            setBillItems(prevBillItems => prevBillItems.filter((item, elIndex) => elIndex !== index));
        } else if (operation === 'totals') {
            setBillItems(prevBillItems => prevBillItems.map(item => {
                return item.id === changedItem.id && item.reactKey === changedItem.reactKey ? changedItem : item
            }));
        }
    }, []);

    const onAddNewRowClick = (index) => {
        dispatch(checkLoggedUser());
        setFocusedField([index, 0])
        const firstPart = billItems.slice(0, index);
        const secondPart = billItems.slice(index, billItems.length);
        const newData = [...firstPart, {
            created_by_user_id: null,
            damage_bill_id: null,
            damage_bill_version_id: null,
            date_created: null,
            date_edited: null,
            deduction_code: null,
            deduction_in_percent: null,
            description: '',
            discount: null,
            edited_by_user_id: null,
            id: '',
            is_taxable_for_second_tax: false,
            is_updated: false,
            item_code: '',
            item_type: Constants.ITEM_TYPE_LABOR,
            paying_code: null,
            price: 0,
            quantity: 0,
            spare_part_id: null,
            total_after_deduction: 0,
            total_for_paying: 0,
            unit: null,
            reactKey: Date.now() // use this for identifying uniquely rows created by user
        }, ...secondPart]
        setBillItems(newData);
    };


    /**
     * When billItems has changed (e.g. new line was added)
     * focus on the selected element
     * TODO: this code probably creates side effects
     * state should not be synced like that
     * REMOVE THIS CODE
     */
        // useEffect(() => {
        //     setFocusedField(focusedField)
        // }, [billItems, focusedField])


    const getNextFocusableField = useCallback(([row, col]: [number, number]): string => {
            if (col + 1 > 7 && row + 1 === billItems.length) { // last row last col
                onAddNewRowClick(billItems.length)
            } else if (col + 1 > 7 && row + 1 < billItems.length) {
                return [row + 1, 0]
            } else {
                return [row, col + 1]
            }
            return [row + 1, 0]
        }, [billItems, onAddNewRowClick])

    useEffect(() => {
        if (!loadingItems) {
            const newItems = [...billItems]
            if (newItems?.length === 0) {
                onAddNewRowClick(0);
            }
        }
    }, [bill, billItems, loadingItems])
    const [selectValueContext, setSelectValueContext] = useState({value: null, label: '', id: null});
    const [selectedCells, setSelectedCells] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const toggleCell = (index) => {
        const newArray = selectedCells.includes(index) ? selectedCells.filter((i) => i !== index) : [...selectedCells, index];
        setSelectedCells(newArray);
    }
    useEffect(() => {
        toggleSelectAll();

    }, [selectAllChecked]);
    const toggleSelectAll = () => {
        if (selectAllChecked === true) {
            const newArray = [];
            billItems?.map((item, index) => {
                newArray.push(index + 1);
            })
            setSelectedCells(newArray);
        } else {
            setSelectedCells([]);
        }
    }
    const refSparePartContainer = useRef(null);
    const refTableItemsContainer = useRef(null);

    useEffect(() => {
        if (!sparePartsForLoadedBillData || !billItems) return;
        refTableItemsContainer.current.style.minHeight = (refSparePartContainer.current?.clientHeight + 170) + 'px';
    }, [sparePartsForLoadedBillData, bill, billItems]);
    const tooltipRef = useRef(null);
    const inputRef = useRef(null);
    const {attributes} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    const {data} = useCountries();
    const allCountries = data && data._embedded && (data._embedded.records.map((item) => {
        return {iso_code: item.country.iso_code, country_id: item.country_id}
    }))

    const countryForBill = allCountries && allCountries.find((item) => item.country_id === country_id);
    const {data: roundAfterTaxes, request: roundAfterTaxesRequest} = useApi(getRoundNumberOfSwiss);
    const {data: roundAfterDeduction, request: roundAfterDeductionRequest} = useApi(getRoundNumberOfSwiss);
    const {data: roundAfterCalculation, request: roundAfterCalculationRequest} = useApi(getRoundNumberOfSwiss);






    useEffect(() => {

        if (typeof (countryForBill?.iso_code) === 'undefined' || typeof (currency?.foreignCurrency) === 'undefined') {
            return;
        } else {
            roundAfterTaxesRequest({
                country: countryForBill?.iso_code,
                currency: currency?.foreignCurrency,
                price: totalAfterTaxes.toFixed(2)
            });
            roundAfterDeductionRequest({
                country: countryForBill?.iso_code,
                currency: currency?.foreignCurrency,
                price: grandTotalAfterDeduction.toFixed(2)
            });
            roundAfterCalculationRequest({
                country: countryForBill?.iso_code,
                currency: currency?.foreignCurrency,
                price: grandTotal.toFixed(2)
            });

        }
    }, [roundAfterTaxesRequest, roundAfterDeductionRequest, roundAfterCalculationRequest, countryForBill?.iso_code, currency?.foreignCurrency, totalAfterTaxes, calculateGrandTotals, bill?.id])



    return (
        <div className={"billItemsAndTotalsHolder"}>
            {contextMenu.show && selectedCells.length !== 0 && !isReadOnly && damage?.damage_number &&
                <div className={'context-menu'}
                     style={{
                         top: showSidebar ? `${contextMenu.y - 90}px` : `${contextMenu.y}px`,
                         left: showSidebar ? `${contextMenu.x - 340}px` : `${contextMenu.x - 50}px`,
                     }}
                     ref={contextMenuRef}
                >
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: 'flex', flexDirection: 'row', paddingInline: '10px', gap: '5px'}}>
                            Select all: <input type="checkbox" checked={selectAllChecked} onChange={() => {
                            setSelectAllChecked(!selectAllChecked);
                        }}/></div>
                        {
                            sparePartsForLoadedBillData?.records?.map((item, index) => {
                                return (<div className={"position-context-menu-item"}
                                             onClick={() => {

                                                 setSelectValueContext({
                                                     value: item?.teilid,
                                                     label: item?.teilbez,
                                                     id: index + 1
                                                 });
                                                 contextMenuClose();
                                                 setSelectedCells([]);
                                                 setSelectAllChecked(false);
                                             }}>
                                    {index + 1} {item?.teilbez}
                                </div>)
                            })
                        }
                    </div>
                </div>}

            <div className={"billItemsTbody"} ref={refTableItemsContainer}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBlock: '7px',
                    position: "sticky",
                    top: 0,
                    background: 'white',
                    zIndex: 2,
                    alignItems: 'center'
                }}>
                    {(!isReadOnly && damage?.damage_number) ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                columnGap: '20px',
                                rowGap: '10px',
                                fontSize: '13px',
                                height: '20%'
                            }}
                            ref={refSparePartContainer}
                        >
                            {sparePartsForLoadedBillData?.records?.map((item, index) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '2px'
                                    }}>
                                        <div style={{
                                            border: '1px solid black',
                                            borderRadius: '10%',
                                            width: '21px',
                                            height: '21px',
                                            textAlign: 'center'
                                        }}>{index + 1}</div>
                                        {item?.teilbez}
                                    </div>)
                            })
                            }
                        </div> : <div></div>}
                    <Button className={"zoomInOutBtn " + zoomInOutBtnClass}
                            onClick={toggleZoomInZoomOutOfItemsAndTotalsHolder} title={zoomInOutBtnTitle}>
                        <BsFullscreen className={"innerBtnPicture zoomIn"}/>
                        <BsFullscreenExit className={"innerBtnPicture zoomInExit"}/>
                    </Button>
                </div>
                <table className={"table_class"}>
                    <thead className={"tableThead"}>
                    <tr className={'Table-Row Table-Row-Items someClass'}>
                        <th className={"hash"}>#</th>
                        <th className={"Type"}>Kostenart</th>
                        <th className={"Code"}>Nummer</th>
                        <th className={"Code"} style={{width: '10ch'}}>Bauteil</th>
                        <th className={"Spare-part"}>Bezeichnung</th>
                        <th className={"Quantity"}>Anzahl</th>
                        <th className={"Price"}>Preis</th>
                        <th className={"Discount"}>Nachlass</th>
                        <th className={"Total-Label-Table"}>Zwischesumme</th>
                        <th className={"Paying-code"}>Abzugscode</th>
                        <th className={"Paying-code"}>Abzug %</th>
                        <th className={"Total-for-payment"}>Gesamtbetrag</th>

                    </tr>
                    </thead>
                    {billItems?.length !== 0 && <tbody>
                    {billItems.map((item, index) => (
                        <FormBillItemsRow
                            key={item.reactKey || item.id}
                            focusedId={focusedField}
                            setFocusedId={setFocusedField}
                            getNextFocusableField={getNextFocusableField}
                            stateChanger={updateItem}
                            item={item}
                            index={index}
                            deductionCodeList={deductionCodeList}
                            addNewItem={onAddNewRowClick}
                            billItems={billItems?.length}
                            handleContextMenu={handleContextMenu}
                            selectValueContext={selectValueContext}
                            setSelectValueContext={setSelectValueContext}
                            toggleCell={toggleCell}
                            selectedCells={selectedCells}
                            setSelectedCells={setSelectedCells}
                            sparePartsForLoadedBill={sparePartsForLoadedBillData}
                            showConvertedValue={showConvertedValue}
                            hideConvertedValue={hideConvertedValue}/>))
                    }</tbody>
                    }
                </table>
            </div>
            <div className={"totals"}>
                <div/>
                <table className={"totalsTable"}>
                    <thead>
                    <tr>
                        <th/>
                        <th>Gesamtbeträge</th>
                        <th>Abzüge</th>
                        <th>Endbetrag nach Abzug</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={"totalLaborTd"}>Lohn</td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeLaborTotal)} onMouseOut={hideConvertedValue}>
                            {numberFormat(typeLaborTotal)}
                            <input type="hidden" name={"total_by_calculation_for_labor"}
                                   value={typeLaborTotal.toFixed(2)}/>
                        </td>
                        <td>
                            {numberFormat(typeLaborTotalDeduction)}
                            <input type="hidden" name={"total_deduction_by_calculation_for_labor"}
                                   value={typeLaborTotalDeduction.toFixed(2)}/>
                        </td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeLaborTotalAfterDeduction)}
                            onMouseOut={hideConvertedValue}>
                            {numberFormat(typeLaborTotalAfterDeduction)}
                            <input type="hidden" name={"total_for_labor_after_deduction"}
                                   value={typeLaborTotalAfterDeduction.toFixed(2)}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Material</td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeSparePartsTotal)}
                            onMouseOut={hideConvertedValue}>
                            {numberFormat(typeSparePartsTotal)}
                            <input type="hidden" name={"total_by_calculation_for_spare_parts"}
                                   value={typeSparePartsTotal.toFixed(2)}/>
                        </td>
                        <td>
                            {numberFormat(typeSparePartsTotalDeduction)}
                            <input type="hidden" name={"total_deduction_by_calculation_for_spare_parts"}
                                   value={typeSparePartsTotalDeduction.toFixed(2)}/>
                        </td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeSparePartsTotalAfterDeduction)}
                            onMouseOut={hideConvertedValue}>
                            {numberFormat(typeSparePartsTotalAfterDeduction)}
                            <input type="hidden" name={"total_for_spare_parts_after_deduction"}
                                   value={typeSparePartsTotalAfterDeduction.toFixed(2)}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Sonstiges</td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeOtherTotal)} onMouseOut={hideConvertedValue}>
                            {numberFormat(typeOtherTotal)}
                            <input type="hidden" name={"total_by_calculation_for_other"}
                                   value={typeOtherTotal.toFixed(2)}/>
                        </td>
                        <td>
                            {numberFormat(typeOtherTotalDeduction)}
                            <input type="hidden" name={"total_deduction_by_calculation_for_other"}
                                   value={typeOtherTotalDeduction.toFixed(2)}/>
                        </td>
                        <td onMouseOver={(e) => showConvertedValue(e, typeOtherTotalAfterDeduction)}
                            onMouseOut={hideConvertedValue}>
                            {numberFormat(typeOtherTotalAfterDeduction)}
                            <input type="hidden" name={"total_for_other_after_deduction"}
                                   value={typeOtherTotalAfterDeduction.toFixed(2)}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Gesamtbetrag</td>
                        <td onMouseOver={(e) => showConvertedValue(e, roundAfterCalculation?.data?.price)} onMouseOut={hideConvertedValue}>
                            {roundAfterCalculation ?.data?.price? roundAfterCalculation?.data?.price : ''}
                            <input type="hidden" name={"total_after_calculation"}
                                   value={roundAfterCalculation?.data?.price}/>
                        </td>
                        <td>
                            {numberFormat(grandTotalDeduction)}
                            <input type="hidden" name={"total_deduction"}
                                   value={grandTotalDeduction.toFixed(2)}/>
                        </td>
                        <td onMouseOver={(e) => showConvertedValue(e, grandTotalAfterDeduction)}
                            onMouseOut={hideConvertedValue}>
                            {roundAfterDeduction?.data?.price? roundAfterDeduction?.data?.price : ''}
                            <input type="hidden" name={"total_after_deduction"}
                                   value={roundAfterDeduction?.data?.price}/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table className={"totalsTable tr"}>
                    <thead>
                    <tr style={{height: "20px"}}>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Rechnungsbetrag</td>
                        <td/>
                        <td onMouseOver={(e) => showConvertedValue(e, grandTotalAfterDeduction)}
                            onMouseOut={hideConvertedValue} className={'text-right'}>
                            {roundAfterDeduction?.data?.price? roundAfterDeduction?.data?.price : ''}
                            <input type="hidden" name={"total_after_deduction"} value={roundAfterDeduction?.data?.price}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"totalLaborTd"}>Mehrwertsteuer</td>
                        <td>{firstVat?.length > 0 && !isNaN(firstVat) ? `${numberFormat(firstVat)}%` : ''}</td>
                        <td onMouseOver={(e) => showConvertedValue(e, !isNaN(firstTax) ? firstTax : 0)}
                            onMouseOut={hideConvertedValue} className={'text-right'}>
                            {!isNaN(firstTax) ? numberFormat(firstTax) : 0}
                            <input type="hidden" name={"first_vat_amount"}
                                   value={!isNaN(firstTax.toFixed(2)) ? firstTax.toFixed(2) : 0}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"totalLaborTd"}>Altteilsteuer</td>
                        <td>{numberFormat(secondVat)} %</td>
                        <td onMouseOver={(e) => showConvertedValue(e, !isNaN(secondTax) ? secondTax : 0)}
                            onMouseOut={hideConvertedValue} className={'text-right'}>
                            {!isNaN(secondTax) ? numberFormat(secondTax) : numberFormat(0)}
                            <input type="hidden" name={"second_vat_amount"}
                                   value={!isNaN(secondTax) ? secondTax.toFixed(2) : 0}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"totalLaborTd"}>Rechnungsbetrag inkl. Steuer</td>
                        <td/>
                        <td style={{
                            color: paymentTotal === roundAfterTaxes?.data?.price ? 'initial' : 'red'
                        }} onMouseOver={(e) => showConvertedValue(e, totalAfterTaxes)} onMouseOut={hideConvertedValue}
                            className={'text-right'}>
                            {`${!!currency?.foreignCurrency ? currency.foreignCurrency : ''} ${roundAfterTaxes?.data?.price? roundAfterTaxes?.data?.price : ''}`}
                            {/*{console.log('countryForBill.iso_code',countryForBill.iso_code); console.log('currency?.foreignCurrency',currency?.foreignCurrency); conosle.log('totalAfterTaxes', totalAfterTaxes);}*/}
                            <input type="hidden" name={"total_after_taxes"}
                                   value={roundAfterTaxes?.data?.price}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Rechnungswährung</td>
                        <td style={{paddingBlock: '10px'}} className={'currency_wrapper'}>
                            {getCurrenciesData?._embedded.records.length ?
                                <Form.Control as={'select'} style={{width: 'fit-content', paddingBlock: '0'}}
                                              onChange={(e) => onChangeCurrencyHandler(e)}
                                              value={getCurrenciesData._embedded.records.find(b => b.currency === selectedCurrency) ? selectedCurrency : ''}
                                              disabled={isReadOnly}>
                                    <option value={''} selected hidden>Wählen</option>
                                    {getCurrenciesData._embedded.records.map(item => <option
                                        value={item.currency}>{item.currency}</option>)}
                                </Form.Control> : currency?.foreignCurrency}

                        </td>
                    </tr>
                    {currency?.rate === null && selectedCurrency ?
                        <tr>
                            <td>Währungskurs</td>
                            <td className={'currency_wrapper'}>
                                <Form.Control style={{maxWidth: '120px'}} type={'number'} disabled={isReadOnly}
                                              value={rate} required onChange={handleRateChange} ref={inputRef}/>
                                <div className={`popper-tooltip ${!rateHasError && 'popper-tooltip-hidden'}`}
                                     ref={tooltipRef}
                                     style={{position: 'absolute', top: '43px', left: '0px'}} {...attributes.popper}>
                                    Der Währungskurs darf nicht leer oder 0 sein
                                </div>
                                <div
                                    className={`currency_message ${(rate || rateHasError) && 'currency_message_hide'}`}>
                                    Der Dienst ist nicht verfügbar. Bitte geben Sie den Währungskurs manuell ein.
                                </div>
                            </td>
                            <td><Button type={'button'} onClick={onClickRateForm} size={'sm'}>Währungskurs
                                speichern</Button></td>
                        </tr>
                        : <tr>
                            <td>Währungskurs</td>
                            <td>{currency?.rate}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
