// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import {useSelector} from "react-redux";
import {usePopper} from "react-popper";

type Props = {
    errorMessage?: string,
    kmValidation: string
};

export function KmInRepair({errorMessage: errorMessageProp, kmValidation: kmValidation}: Props) {
    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [errorMessage, setErrorMessage] = useState<any>(errorMessageProp);
    const [kmInRepair, setKmInRepair] = useState(bill?.vehicle_mileage_after_reparation || '')
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });
    useEffect(() => {
        setErrorMessage(errorMessageProp)
        if (update) update().then()
    }, [errorMessageProp, update])

    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setKmInRepair('')
        setErrorMessage(undefined)
        if (update) update().then()
    }, [bill, update])

    useEffect(() => {
        setKmInRepair(bill?.vehicle_mileage_after_reparation || '')
    }, [bill])

    function handleChange(e) {
        setKmInRepair(e.target.value);
        setErrorMessage(undefined)
        if (update) update().then()
    }

    return (
        <div className={"Field-Input-Drop"} style={{flex: '2 1 160px', maxWidth: '160px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>KM-Stand Reparatur *</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={`Input-text ${kmValidation}`} type="totalText" value={kmInRepair}
                                  name="vehicle_mileage_after_reparation"
                                  onChange={handleChange}
                                  disabled={isReadOnly}
                                  ref={inputRef}
                    />
                </div>
                <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                     style={styles.popper} {...attributes.popper}>
                    {errorMessage && (errorMessage.notDigits || errorMessage.isEmpty)}
                    <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                </div>


            </Form.Group>
        </div>

    );
}
