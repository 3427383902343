import {AxiosResponse} from "axios";
import {client, headers} from "../../app/axiosClient";
import {countriesUrl} from "../ApiService";

export function getCountries(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}

export function getCountriesByLocale(locale: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}?languageCode=${locale}`, {
        headers: {
            ...headers
        }, cancelToken
    })
}

export function getAllCurrencies(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}/currencies`, {
        headers: {
            ...headers,
        },
        cancelToken: cancelToken
    })
}
export function getRoundNumberOfSwiss(country: string, currency: string, price: string | number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}/round?country=${country}&currency=${currency}&price=${price}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}
