//@flow
import React, {useEffect, useRef, useState} from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import rgisLogo from '../../assets/images/RealGrantLogo.svg'
import toast, {Toaster} from 'react-hot-toast';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {cleanError, login} from '../../features/Authentication/authSlice'


export const inputErrorStyle = {
    borderColor: 'red'
}

function LoginPage() {
    const {error} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (window.name === 'childWindow') window.close();
        const existingWin = window.open('', 'childWindow');
        existingWin && existingWin.close();
    }, []);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const toastIdRef = useRef<string>('')

    const cleanErrors = () => {
        dispatch(cleanError())
        toast.dismiss(toastIdRef.current)
    }

    const onLoginSubmit = async (e: Event) => {
        toastIdRef.current = toast.loading('Validating', {
            id: toastIdRef.current
        })
        e.preventDefault();
        try {
            await dispatch(login({username, password})).unwrap()
            cleanErrors()
            history.push('/');
        } catch (e) {
            if(e.error==='User deactivated'){
                toast.error('Unfortunately it is not possible to register', {
                    id: toastIdRef.current,
                })
            }
            else {
                toast.error('Invalid Credentials', {
                    id: toastIdRef.current,
                });
            }

        }
    }

    return (
        <>
            <Toaster position={'bottom-center'}/>
            <main style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'left',
            }}>
                <div style={{
                    padding: '1rem',
                    width: '100%',
                    maxWidth: '400px',
                    display: "flex",
                    flexDirection: 'column'
                }}>

                    <img style={{
                        width: '100%',
                        marginBottom: '1.5rem'
                    }} alt={'Real Garant logo'} src={rgisLogo}/>
                    <Form style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '.3rem'
                    }} onSubmit={onLoginSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control style={error ? inputErrorStyle : {}} type="text" value={username}
                                          onChange={(e) => {
                                              cleanErrors()
                                              setUsername(e.target.value)
                                          }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Form.Label>Password</Form.Label>
                                {/*<Link style={{*/}
                                {/*    fontSize: '.9em',*/}
                                {/*    marginBottom: '.5em'*/}
                                {/*}} to={'/reset-password'}>Forgot password?</Link>*/}
                            </div>
                            <Form.Control style={error ? inputErrorStyle : {}} type="password" value={password}
                                          onChange={(e) => {
                                              cleanErrors()
                                              setPassword(e.target.value)
                                          }}/>
                        </Form.Group>
                        <Button variant="primary" style={{
                            width: '100%'
                        }} type="submit">
                            Log in
                        </Button>
                    </Form>
                </div>
            </main>
        </>
    )
}

export default LoginPage
