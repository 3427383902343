// @flow
import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {CancelTokenSource} from "axios";
import {useHistory} from "react-router";


export interface UseApiConfig {
    cancelPreviousOngoing?: boolean
}

const initUseApiConfig: UseApiConfig = {
    cancelPreviousOngoing: true
}


export type UseApiRequestFunction<P> = (argObjToPassDown: P, requestCancellation?: boolean) => void

/**
 * Custom Hook for making Api Call
 * Supports cancellation and returns the data from response, error (if occurred), loading status, function that calls the apiFunc in parameters
 * @param apiFunc function that uses axios and returns a promise
 * @param apiConfig
 * @returns {{canceled: boolean, request: (function(...[*]): Promise<void>), data: T, reset: (function(): void), error: string, loading: boolean}}
 */
export default function useApi<T>(apiFunc: () => Promise, apiConfig: UseApiConfig = initUseApiConfig): {
    data: T,
    error: string,
    loading: boolean,
    request: UseApiRequestFunction
} {
    const [data, setData] = useState<T | null>(null); // stores data from axios response
    const [error, setError] = useState<string>(""); // can be used to display an error message
    const [loading, setLoading] = useState<boolean>(false); // can be used to display a loading spinner
    const [canceled, setCanceled] = useState<boolean>(false); // use this to check if the request has been canceled
    const isRequesting = useRef<boolean>(false);
    const history = useHistory();

    /**
     * We need to cancel an ongoing request if the hook is destroyed
     * we can use Axios Cancel Token mechanism
     * Using useRef instead of useState is essential here because we want to persist values between renders
     * @type {{current: (CancelTokenSource|null)}}
     */
    const cancelRequestSource = useRef<CancelTokenSource>(axios.CancelToken.source());

    // const controller = new AbortController();


    /**
     * Calls the function that sends the appropriate request from axios
     */
    const request = useCallback<UseApiRequestFunction>(async (argObjToPassDown, requestCancellation) => {
        setLoading(true)

        // this cancels previous request if we are still waiting for a response
        if (isRequesting.current) {
            if (typeof requestCancellation === 'undefined') {
                if (apiConfig.cancelPreviousOngoing) {
                    cancelRequestSource.current.cancel("cancel");
                    cancelRequestSource.current = axios.CancelToken.source();
                }
            } else if (requestCancellation) {
                cancelRequestSource.current.cancel("cancel");
                cancelRequestSource.current = axios.CancelToken.source();
            }

        } else {
            isRequesting.current = true;
        }

        // controller.abort()

        try {
            // console.log(...Object.values(argObjToPassDown || []), cancelRequestSource.current.token)
            const {data} = await apiFunc(...Object.values(argObjToPassDown || []), cancelRequestSource.current.token);
            setData(data);
            setLoading(false);

            return data;
        } catch (err) {
            console.log('err', err?.response?.data?.message);
            if (axios.isCancel(err)) {
                setCanceled(true)
                return;
            }
            if (err.response?.data.code === 401 && (err.response?.data.message === 'Access token expired' || data?.data.response === 'Refresh token expired')) {
                history.push('/login');
            }
            setData(err.response?.data);
            setError(err.response?.message || err.response?.data?.message || err.message || "Unexpected Error!");
            setLoading(false);
        } finally {
            isRequesting.current = false;
        }
    }, [apiFunc]);

    /**
     * Resets everything to the initial state
     * It can be used before calling request() if developer has already called it once and state is dirty
     */
    const reset = useCallback(() => {
        setData(null);
        setError("");
        setCanceled(false);
        setLoading(false);
        setError(false);
        isRequesting.current = false;
    }, [])


    /**
     * On destroy cancel ongoing request
     */
    useEffect(() => {
        return () => {
            cancelRequestSource.current.cancel("cancel") // cancel request
        }
    }, [])


    return {
        data,
        error,
        loading,
        canceled,
        request,
        reset
    };
};
