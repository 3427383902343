// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import dateFormat from 'dateformat';
import {validateCustomDateFieldByParameters} from "./RepairDate";
import {useSelector} from "react-redux";

export function AcceptanceDate({acceptanceDateValidation}) {
    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [validFieldClass, setValidFieldClass] = useState('');
    const [acceptanceDate, setAcceptanceDate] = useState(bill?.acceptance_date?.date ? dateFormat(bill.acceptance_date.date, "dd.mm.yyyy") : '')


    useEffect(() => {
        setAcceptanceDate(bill?.acceptance_date?.date ? dateFormat(bill.acceptance_date.date, "dd.mm.yyyy") : '')
        setValidFieldClass('')
    }, [bill])

    function handleChange(e) {
        setValidFieldClass('')
        setAcceptanceDate(e.target.value);
    }

    function handleBlur(e) {
        if (validateCustomDateFieldByParameters(e.target.value, e, '.', '210')) {
            setValidFieldClass('');
        } else {
            setValidFieldClass('is-invalid');
        }
        setAcceptanceDate(e.target.value);
    }

    return (
        <div className={"Field-Input-Drop"} style={{maxWidth: '94px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>Annahmedatum</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={validFieldClass + `Input-text ${acceptanceDateValidation}`} type="dateText"
                                  name="acceptance_date" id="acceptance_date"
                                  value={acceptanceDate}
                                  onBlur={(e) => handleBlur(e)}
                                  onChange={(e) => handleChange(e)}
                                  disabled={isReadOnly}
                    />
                </div>


            </Form.Group>
        </div>
    );
}
