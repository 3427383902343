// @flow
import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDamage, reFetchDamageBill} from "../../../features/DamageBill/bill.actions";
import DamageInfoItems from "./DamageInfoItems";
import BindToClaimModal from "./BindToClaimModal";
import DamageInfoCTAs from "./DamageInfoCTAs";
import useOpenWindow from "../../../hooks/useOpenWindow";
import './damage-info.css'
import {checkLoggedUser} from "../../../features/Authentication/authSlice";
import PaymentLetterModal from "./PaymentLetterModal";

function DamageInfo() {
    const {bill, isManualSetup} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const [showPaymentLetterModal, setShowPaymentLetterModal]= useState(false);
    const {openWindow: openDocumentsWindow, isClosed} = useOpenWindow(`/documents/${bill?.damage_bill_id}`);
    const [refreshLogsIndicator, setRefreshLogsIndicator]=useState(0);


    const handleClose = useCallback(() => {
        setShow(false);
    }, []);

    const handleShow = useCallback(async () => {
        await dispatch(checkLoggedUser());
        setShow(true);
    }, [dispatch]);

    const handleClosePaymentModal = useCallback(() => {
        setShowPaymentLetterModal(false);
    }, []);

    const handleShowPaymentModal = useCallback(() => {
        setShowPaymentLetterModal(true);
    }, []);

    const handleChangeLogsIndicator = () =>{
        setRefreshLogsIndicator(prevState => prevState +1);
    }

    useEffect(() => {

        /**
         * It seems that there is a bug with Firefox v95.0.2
         * and calling window.open throws "Should not already be working." error message from
         * performSyncWorkOnRoot function.
         * Wrapping it with setTimeout fixes the issue
         * Note: calling 'openDocumentsWindow' from an event callback does not throw an error
         */
        dispatch(checkLoggedUser());
        setTimeout(() => {
            if (bill?.damage_bill_id && !isClosed) {
                openDocumentsWindow();
            }
        }, 0)
    }, [openDocumentsWindow, bill, isClosed]);

    /**
     * When 'bill' is available or changed
     * fetch data based on 'damage_id' if it is available
     */
    useEffect(() => {
        if (!bill) return;
        const {damage_id} = bill.damage_bill;

        if (damage_id) {
            dispatch(fetchDamage({damage_id, mileage: bill.vehicle_mileage_after_reparation}))
        }
    }, [dispatch, bill])


    /**
     * We need to fetch bill again after updating damage for this damageBill
     */
    const reFetchBill = useCallback(async () => {
        await dispatch(checkLoggedUser());
        dispatch(reFetchDamageBill())
    }, [dispatch])

    return (
        <div className={"DamageInfo"}>
            {!isManualSetup && <BindToClaimModal show={show} onClose={handleClose} onSuccessfulSelect={reFetchBill}/>}
            {!isManualSetup && <PaymentLetterModal show={showPaymentLetterModal} onClose={handleClosePaymentModal} changeIndicator={handleChangeLogsIndicator} />}
            <DamageInfoCTAs showClaimsModal={handleShow}
                            showDocumentsWindow={openDocumentsWindow} showPaymentLetterModal={handleShowPaymentModal} refreshLogsIndicator={refreshLogsIndicator}/>
            {!isManualSetup && <DamageInfoItems/>}

        </div>
    );
}

export default DamageInfo;

